import { AnchorLink, AnchorLinkType } from '../anchorLink';
import { Icon, IconsEnum } from '../Icons';

import React from 'react';
import { Wrapper } from '../wrapper';
import styles from './LearnMore.module.scss';

export type LearnMoreProps = {
  title: string;
  links: Link[];
  linksToCustomAction?: { id: string; action: () => void }[];
  noPaddingLeft?: boolean;
};

export type Link = {
  id: string;
  label: string;
  linkBlock: AnchorLinkType[];
};

const iconArrow = <Icon iconId={IconsEnum.ARROW} className={styles.arrowIcon} color="var(--color-primary)" />;
const iconDownload = (
  <Icon iconId={IconsEnum.DOWNLOAD_VIRTUAL} className={styles.arrowIcon} color="var(--color-primary)" />
);

export const LearnMore: React.FC<LearnMoreProps> = ({
  links,
  title,
  noPaddingLeft,
  linksToCustomAction,
}: LearnMoreProps) => {
  return (
    <Wrapper bgColorTransparent noPaddingLeft={noPaddingLeft}>
      <div className={styles.container}>
        <h3 className={styles.title}>{title}</h3>
        <ul className={styles.links}>
          {links.map(({ id, label, linkBlock }) => {
            const customAction = linksToCustomAction?.find(
              (e) => e.id === (linkBlock[0].href as string).replace('/', '')
            );
            return (
              <li key={id + '_' + linkBlock} className={styles.item}>
                {linkBlock[0].href && customAction ? (
                  <button className={styles.link} onClick={customAction.action}>
                    {label}
                    {iconArrow}
                  </button>
                ) : (
                  <AnchorLink
                    className={styles.link}
                    href={linkBlock[0].href}
                    download={linkBlock[0].download || undefined}
                  >
                    {label}
                    {linkBlock?.[0]?.download ? iconDownload : iconArrow}
                  </AnchorLink>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </Wrapper>
  );
};
