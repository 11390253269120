import { AnchorLink, AnchorLinkType } from '../anchorLink';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Wrapper } from '../wrapper';
import styles from './InternalMenu.module.scss';
import { useRouter } from 'next/router';
import { isNotEmpty } from 'utility/functions';
import Link from 'next/link';
import classNames from 'classnames';
export type InternalMenuProps = {
  items: InternalMenu[];
  isLocalStateInternalMenu?: boolean;
  itemSelected?: string;
  noPaddingList?: boolean;
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
  className?: string;
  noPaddingLeft?: boolean;
  noPaddingRight?: boolean;
  smallFont?: boolean;
  wrapperClassName?: string;
  isFullWidth?: boolean;
  gtagAction?: (_href: string) => void;
  itemSelectedCallback?: (_id: string) => void; // React.MouseEventHandler<HTMLAnchorElement>; //JSX.IntrinsicElements['button']['onClick']; //React.MouseEventHandler<HTMLAnchorElement>; //(id: string) => void;
};
export type InternalMenu = {
  id: string;
  label: string;
  linkBlock: AnchorLinkType[];
};
export const InternalMenu: React.FC<InternalMenuProps> = ({
  items,
  itemSelected,
  noPaddingList,
  noPaddingTop = true,
  noPaddingBottom = true,
  className,
  noPaddingLeft = true,
  noPaddingRight = true,
  smallFont = false,
  isFullWidth = false,
  isLocalStateInternalMenu,
  gtagAction,
  itemSelectedCallback,
}: InternalMenuProps) => {
  const { asPath } = useRouter();

  const isInitialized = useRef(false);

  const [sel, setSel] = useState(itemSelected);

  const onItemClickHandler = useCallback(
    (item: InternalMenu, userAction = true) => {
      setSel((prev) => {
        if (itemSelectedCallback && `${prev}` !== `${item?.id}`) {
          itemSelectedCallback(item.id);
        }
        return item.id;
      });

      if (userAction && gtagAction) {
        gtagAction(item.label);
      }
    },
    [gtagAction, itemSelectedCallback]
  );

  const isSelectedItem = useCallback(
    (item: InternalMenu): boolean => {
      return (
        (isLocalStateInternalMenu === true && itemSelected === item.id) || asPath === (item.linkBlock?.[0]?.href ?? '')
      );
    },
    [asPath, isLocalStateInternalMenu, itemSelected]
  );

  useEffect(() => {
    if (itemSelected && !sel) {
      setSel(itemSelected);
    }
  }, [itemSelected, sel]);

  useEffect(() => {
    if (sel && !itemSelected && !isInitialized.current) {
      isInitialized.current = true;
      const item = items.find((el) => el.id === sel);
      if (item) {
        onItemClickHandler(item, false);
      }
    }
  }, [itemSelected, items, sel, onItemClickHandler]);

  const renderLink = (item: InternalMenu) => {
    const [link] = Array.isArray(item.linkBlock) ? item.linkBlock : [];
    const url = `${link?.href}`;
    const isValidLink = isNotEmpty(url);

    if (!isValidLink || isLocalStateInternalMenu) {
      return (
        <a className={styles.link} onClick={() => onItemClickHandler(item)}>
          {item.label}
        </a>
      );
    }

    const isInternal =
      url.toLowerCase().startsWith('/') ||
      url.toLowerCase().startsWith(`${process.env.NEXT_PUBLIC_BASE_URL}`.toLowerCase());

    if (isInternal) {
      return (
        <Link href={`${item.linkBlock[0].href}`} className={styles.link} onClick={() => onItemClickHandler(item)}>
          {item.label}
        </Link>
      );
    }

    return (
      <AnchorLink href={`${item.linkBlock[0].href}`} className={styles.link} onClick={() => onItemClickHandler(item)}>
        {item.label}
      </AnchorLink>
    );
  };

  return (
    <Wrapper
      bgColorTransparent={true}
      noPaddingRight={noPaddingRight}
      noPaddingLeft={noPaddingLeft}
      noPaddingTop={noPaddingTop}
      noPaddingBottom={noPaddingBottom}
      fullWidth={isFullWidth}
    >
      <div className={classNames(styles.container, className ? styles[className] : '')}>
        <ul
          className={classNames(styles.list, {
            [styles.noPaddingList]: noPaddingList,
            [styles.smallPadding]: smallFont,
          })}
        >
          {items?.map((item) => {
            const isActive = isSelectedItem(item);
            return (
              <li
                key={item.id}
                className={classNames(styles.item, isActive ? styles.itemActive : styles.itemDisable, {
                  [styles.smallFont]: smallFont,
                })}
              >
                {renderLink(item)}
              </li>
            );
          })}
        </ul>
      </div>
    </Wrapper>
  );
};
