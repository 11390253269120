import {
  useGetCardGameQuery,
  useGetCardGamesQuery,
  useGetMightLikesQuery,
  useGetQuickSlotQuery,
  useLazyGetUrlLaunchGameQuery,
} from 'features/api/apiSlice';
import { BANNER_ON_PAGE_PROMO, HERO_BANNER_CMS, HERO_BANNER_PROMO } from 'lib/datoCms/constants';
import React, { useCallback, useMemo } from 'react';

import { BannerHero } from 'components/banner/bannerHero';
import { SlotCardType } from 'components/cards/slotCard';
import { DefaultCarousel } from 'components/carousels/defaultCarousel';
import { SuggestionCarousel } from 'components/carousels/suggestionCarousel';
import { HeaderTitleWithSearch } from 'components/headerTitleWithSearch';
import { InternalMenu } from 'components/internalMenu';
import { LearnMore } from 'components/learnMore';
import { Skeleton } from 'components/skeleton';
import { TabsWithAccordion } from 'components/tabsWithAccordion';
import { VisualComposer } from 'components/visualComposer';
import { createPageComposition } from 'components/visualComposer/utils/utils';
import { useGetPageBannersQuery } from 'features/api/promoEngineApiSlice';
import { useGetMightLikesByList } from 'hooks/useGetAllMightLikes';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import { useGetLabelByKey } from 'hooks/useLingUI';
import { CardGameTopPageQuery } from 'lib/datoCms/queries/getCardGamesTopPage';
import { handleBanner } from 'lib/datoCms/utils';
import { NextPage } from 'next';
import { WithRouterProps } from 'next/dist/client/with-router';
import { withRouter } from 'next/router';
import { Category } from 'types/categoryEnum';
import { GamesSkillGameDto } from 'types/swagger';
import { FAVORITE_LIST } from 'utility/constant';
import { conditionsToVisualizeBanner } from 'utility/functions';
import { getGamesSlotByType } from '../slot-top/utils/utils';
import { Button } from 'components/button';
import { useInternalSession } from 'hooks/useInternalSession';
import { launchFantawinner } from 'lib/launchGame/utils';
import { setIsOpenLoginModalValue } from 'features/modal/modalSlice';
import { useAppDispatch, useTypedSelector } from 'lib/centralStore';
import { Wrapper } from 'components/wrapper';
import { selectIsActiveFantawinner } from 'features/configuration/selectors';

type Props = {
  cardGamesTopPage: CardGameTopPageQuery['cardGamesTopPage'];
  // choseFromSnaiCardSearch: SlotCardType;
  choseFromSnaiCardSearch: string;
} & WithRouterProps;

const PageContent: NextPage<Props> = ({ cardGamesTopPage, choseFromSnaiCardSearch }: Props) => {
  const isLoggedIn = useIsAuthenticated();
  const favoriteLabel = useGetLabelByKey('preferiti')!;
  const { isLoading, isError, data: cardGamesCatalogResponseApi } = useGetCardGamesQuery();
  const [trigger] = useLazyGetUrlLaunchGameQuery();
  const { session } = useInternalSession();
  const dispatch = useAppDispatch();
  const isActiveFantawinner = useTypedSelector(selectIsActiveFantawinner);

  // promoEngine
  const { isError: isErrorPromoEngine, data: promoEngine } = useGetPageBannersQuery(cardGamesTopPage.id);

  const {
    data: quickSlotResponseApi,
    isLoading: quickSlotIsLoading,
    isError: isErrorQuickSlot,
  } = useGetQuickSlotQuery();

  const labelSearch = useGetLabelByKey('search')!;
  const apiGenericErrorLabel = useGetLabelByKey('label-api-generic-error');

  const { visualComposer } = cardGamesTopPage;

  const { data: cardChoseFromSnai } = useGetCardGameQuery(choseFromSnaiCardSearch ?? '', {
    refetchOnMountOrArgChange: true,
  });
  const { data: mightLikesResponseApi } = useGetMightLikesQuery(cardGamesTopPage.slug, {
    refetchOnMountOrArgChange: true,
  });
  let mightLikesList = useGetMightLikesByList(mightLikesResponseApi?.cardList);

  const listQuickSlot = useMemo(() => {
    if (quickSlotResponseApi) return quickSlotResponseApi.games;
    return;
  }, [quickSlotResponseApi]);

  const pageBannerList = useMemo(() => {
    if (
      conditionsToVisualizeBanner(cardGamesTopPage, {
        type: BANNER_ON_PAGE_PROMO,
        data: promoEngine,
        hasError: isErrorPromoEngine,
      })
    ) {
      return promoEngine!.pageBannerList;
    }
    return undefined;
  }, [cardGamesTopPage, promoEngine, isErrorPromoEngine]);

  const gamesFavorite = useMemo(
    () => cardGamesCatalogResponseApi && getGamesSlotByType(cardGamesCatalogResponseApi, FAVORITE_LIST),
    [cardGamesCatalogResponseApi]
  );

  const handleLaunch = useCallback(async () => {
    if (session && session.user && session.user.name) {
      const response = await trigger({
        username: session.user.name,
        gameCodeName: process.env.NEXT_PUBLIC_FANTAWINNER_GAME_CODE_NAME,
        codPiattaforma: 152,
        playMode: 1,
        modalita: '',
      }).unwrap();
      if (response.url) launchFantawinner(response.url, { height: 900, width: 800 });
    } else {
      dispatch(setIsOpenLoginModalValue(true));
    }
  }, [trigger, session, dispatch]);

  const newVisualComposer = useMemo(() => {
    const newGiochidiCarteCatalog = {
      ...cardGamesCatalogResponseApi!,
      listQuickSlot,
      pageBannerList,
    };

    return (
      visualComposer &&
      newGiochidiCarteCatalog &&
      createPageComposition<Array<GamesSkillGameDto>>({
        visualComposer,
        catalog: newGiochidiCarteCatalog,
        slug: cardGamesTopPage.slug,
      }).map((e) => e!)
    );
  }, [cardGamesCatalogResponseApi, cardGamesTopPage.slug, listQuickSlot, pageBannerList, visualComposer]);

  if (isError || isErrorQuickSlot) {
    return <p>{apiGenericErrorLabel}</p>;
  }
  return (
    <React.Fragment>
      {cardGamesTopPage.title && (
        <HeaderTitleWithSearch
          title={cardGamesTopPage.title}
          category={Category.CardGame}
          labelSearch={labelSearch}
          cardChoseFromSnai={cardChoseFromSnai as unknown as SlotCardType}
          hasPaddingLeft
        />
      )}
      {isActiveFantawinner && (
        <Wrapper bgColorTransparent>
          <Button size="medium" visualizationType="primary" onClick={handleLaunch}>
            LANCIO GIOCO FANTAWINNER
          </Button>
        </Wrapper>
      )}

      {conditionsToVisualizeBanner(cardGamesTopPage, {
        type: HERO_BANNER_PROMO,
        data: promoEngine,
        hasError: isErrorPromoEngine,
      }) && <BannerHero banners={promoEngine!.heroBannerList} />}
      {conditionsToVisualizeBanner(cardGamesTopPage, {
        type: HERO_BANNER_CMS,
      }) && <BannerHero banners={handleBanner(cardGamesTopPage.heroBanner[0].banners!)} />}
      {cardGamesTopPage.internalMenu && <InternalMenu items={cardGamesTopPage.internalMenu[0].linkList} />}

      {isLoading && quickSlotIsLoading ? (
        <Skeleton hasTitle hasPadding rows={3} />
      ) : (
        <React.Fragment>
          {gamesFavorite && gamesFavorite.length > 0 && isLoggedIn && (
            <DefaultCarousel title={favoriteLabel} typeOfItem="giochidicarte" listItem={gamesFavorite} />
          )}
          {newVisualComposer && <VisualComposer type="giochidicarte" components={newVisualComposer} />}
        </React.Fragment>
      )}
      {mightLikesList && mightLikesList.length > 0 && <SuggestionCarousel listItem={mightLikesList} />}
      {cardGamesTopPage.learnMore && (
        <LearnMore title={cardGamesTopPage.learnMore.title} links={cardGamesTopPage.learnMore.links} />
      )}
      {cardGamesTopPage.tabsWithAccordion && <TabsWithAccordion {...cardGamesTopPage?.tabsWithAccordion} />}
    </React.Fragment>
  );
};

export default withRouter(PageContent);
